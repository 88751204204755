<template>
  <div class="modal full-screen-image-modal" @click="closeModal">
    <div class="modal__body full-screen-image-modal__body">
      <img :src="imageLink" :alt="imageAlt">
    </div>
    <div class="modal__back full-screen-image-modal__back"></div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenImageModal',
  data () {
    return {
      link: null,
      alt: null
    }
  },
  props: {
    imageLink: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    handleEscape (event) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    },
    handleArrowLeft (event) {
      if (event.key === 'ArrowLeft') {
        this.$emit('backImage')
      }
    },
    handleArrowRight (event) {
      if (event.key === 'ArrowRight') {
        this.$emit('nextImage')
      }
    },
    handleKeydown (event) {
      this.handleEscape(event)
      this.handleArrowLeft(event)
      this.handleArrowRight(event)
    }
  },
  beforeMount () {
    this.link = this.imageLink
    this.alt = this.imageAlt
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeydown)
  }
}
</script>

<style lang="stylus" scoped>
.full-screen-image-modal__body
  display flex
  max-height calc(100vh - 80px)
  height 100%
  max-width 95vw
  justify-content center
  align-items center
  overflow hidden

  img
    max-width 100%
    max-height 100%
    object-fit contain
</style>
